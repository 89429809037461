<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div>
      <div class="gradient"></div>
      <div class="px-5 pb-10 mt-n10">
        <v-row no-gutters>
          <v-col cols="12" md="2">
            <div class="mb-3 d-flex align-start justify-center">
              <div
                v-if="!loading && dataSet"
                style="position: relative;width:150px;height;150px;"
              >
                <v-avatar size="150">
                  <img
                    :src="avatar ? avatar : ava"
                    class="obj_fit"
                    style="background: white; padding: 1px"
                  />
                </v-avatar>

                <div style="position: absolute; top: 100px; right: 0px">
                  <v-btn
                    depressed
                    fab
                    color="#052633"
                    x-small
                    dark
                    @click="$refs.file.click()"
                  >
                    <input
                      hidden
                      type="file"
                      ref="file"
                      @change="uploadImage($event)"
                      accept="image/*"
                      class="input_hide"
                    />
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                class="rounded-circle"
                width="150px"
                height="150px"
                type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" class="px-4">
            <div class="d-flex justify-end">
              <v-btn
                :disabled="
                  !loadingSave && !loading ? (edit ? true : false) : true
                "
                @click="edit = true"
                class="text-capitalize"
                text
                depressed
                dark
                style="background: rgba(38, 38, 38, 0.3)"
              >
                <v-icon class="mr-2">mdi-pencil</v-icon>
                Edit Profile
              </v-btn>
            </div>
            <div class="mt-5" v-if="!loading && dataSet">
              <div v-for="(item, n) in dataSet" :key="n">
                <div class="mt-3" v-if="item.type == 'string'">
                  <p class="medium_txt bold ma-0">{{ item.label }}</p>
                  <v-text-field
                    :disabled="edit ? false : true"
                    v-model="item.value"
                    class="rounded-lg"
                    outlined
                    :placeholder="`Silakan isi ${item.key} di sini`"
                    color="#0D47A1"
                    dense
                    hide-details
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="mt-5" v-if="loading">
              <div class="mt-3" v-for="(item, n) in 5" :key="n">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>

            <div v-if="edit" class="d-flex align-center justify-end mt-4">
              <v-btn
                :disabled="loadingSave ? true : false"
                depressed
                class="mr-4 text-capitalize"
                outlined
                color="#BD9028"
                @click="edit = false"
              >
                <div style="color: black">Cancel</div>
              </v-btn>
              <v-btn
                depressed
                class="text-capitalize bg_default"
                @click="updateData"
                dark
              >
                <h4 v-if="!loadingSave">Simpan</h4>
                <h4 v-if="loadingSave">Loading</h4>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pa-2">
        <dialog-medium
          :d_medium="d_create"
          @refetch="fetchData"
          @close="d_create = false"
          :inventory="inventory"
          :type="d_type"
          :title="d_title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { NavbarProfile },
  name: "updateEmployee",
  props: ["selected"],
  computed: {
    ...mapState({
      role: (state) => state.role,
      ava: (state) => state.ava,
    }),
  },
  data() {
    return {
      dataSet: null,
      showPickerLahir: "",
      loading: false,
      edit: false,
      loadingSave: false,
      avatar: null,
      optGender: [
        { text: "Laki-laki", val: "Laki-laki" },
        { text: "Perempuan", val: "Perempuan" },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Profile",
          disabled: true,
          href: "#",
        },
      ],
      image: {
        src: "",
        type: "image/jpg",
      },
      d_create: false,
      inventory: "",
      d_type: "",
      d_title: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let tempProfile = [];
      let form = [];
      let data = {
        path: `auth/profile`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.fetchProfile();
          form = data.data.profile.default_fields;
          this.avatar = data.data.profile.avatar;
          for (let i in data.data.account) {
            if (i != "id") {
              tempProfile.push({ [i]: data.data.account[i] });
            }
          }
          for (let i in data.data.profile) {
            if (i != "default_fields") {
              tempProfile.push({ [i]: data.data.profile[i] });
            }
          }
          tempProfile.forEach((item) => {
            for (let i = 0; i < form.length; i++) {
              if (form[i].key == Object.keys(item)) {
                form[i] = {
                  label: form[i].label,
                  key: form[i].key,
                  required: form[i].required,
                  type: form[i].type,
                  value: Object.values(item)[0],
                };
              }
            }
          });
          this.dataSet = form;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    updateData() {
      let isEmpty = false;
      let body = [];
      this.dataSet.forEach((item) => {
        if (item.required) {
          if (!item.value) {
            this.$toast.error(`${item.key} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
        if (item.value) {
          body.push({ [item.key]: item.value });
        }
      });
      if (!isEmpty) {
        this.loadingSave = true;
        let data = {
          body: body,
          path: `auth/profile/update`,
        };
        this.$store
          .dispatch("fileData/postApi", data)
          .then((data) => {
            this.loadingSave = false;
            if (data.status == "success") {
              this.edit = false;
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
              this.fetchData();
            } else {
              if (data.data) {
                var key = Object.keys(data.data.errors);
                var values = Object.values(data.data.errors);
                for (let i = 0; i < key.length; i++) {
                  for (let j = 0; j < values[i].length; j++) {
                    this.$toast.error(values[i][j]);
                  }
                }
              } else {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
            }
          })
          .catch((e) => {
            this.loadingSave = true;
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          });
      }
    },
    closeDateMenu(item, type) {
      if (type == "lahir") {
        this.showPickerLahir = false;
        this.dataSet.tgl_lahir == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    uploadImage(event) {
      /// Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.image = {
          src: blob,
          type: files[0].type,
        };
        this.showCrop();
      }
    },
    showCrop() {
      this.inventory = this.image;
      this.d_type = "crop";
      this.d_title = "Ubah Foto Profile";
      this.d_create = true;
    },
    fetchProfile() {
      let data = {
        path: "auth/profile",
      };
      this.$store.dispatch("fileData/getData", data).then((data) => {
        this.$store.commit("GET_PROFILE", data);
      });
    },
  },
};
</script>
<style scoped>
.gradient {
  height: 200px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(5, 38, 51, 0.8) 0%,
    #052633 100%
  );
  border-radius: 120px 0px 0px 0px;
  display: flex;
}
</style>
